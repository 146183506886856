import { useCookie } from 'nuxt/app';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(async ({ vueApp }) => {
  const route = useRoute();
  const options = { secure: true, path: '/', sameSite: 'Strict' };
  const lang = useCookie('lang', options);
  // 如果 URL 中有 lang 参数，将其设置为 cookie
  if (route.query.lang) {
    lang.value = route.query.lang;
  } else if (!lang.value) {
    // 如果没有设置 cookie，则将其设置为默认值
    lang.value = 'en';
  }
  const { createI18n } = await import('vue-i18n');

  let messages;
  try {
    // 尝试导入当前语言的资源
    messages = await import(`@/locales/${lang.value}.json`);
  } catch (error) {
    // 如果导入失败，导入英文资源
    messages = await import('@/locales/en.json');
    // 并将当前语言设置为英文
    lang.value = 'en';
  }

  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    defaultLocale: 'en',
    fallbackLocale: 'en',
    locales: lang.value,
    messages: {
      [lang.value]: messages.default
    }
  });

  vueApp.use(i18n);
});
