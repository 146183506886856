export default defineNuxtRouteMiddleware(() => {
   // const { $isLogin } = useNuxtApp();
   // useState("loginState").value = $isLogin();
   // if (!$isLogin()) {
   //    if(to.path.includes('/rss/custom-topic')){
   //       return navigateTo(`/rss/explore-topic/all`)
   //    }
   // } else if ($isLogin()) {
   //    if(to.path === '/login') {
   //       return navigateTo(`/`)
   //    }
   // }
})
