export default class ImageUtil {
  static cosPrefix = 'https://xgamer-1300342626.cos.ap-singapore.myqcloud.com';
  static cdnPrefix = 'https://cdn.xgamer.gg';
  static defaultImageUrl = 'https://xgamer.gg/img/ic_image_placeholder.webp';

  /**
   * 检查是否是cdn的图片
   * */
  static canProcessByCdn = (url: string): boolean => {
    if (!url) {
      return false;
    }
    return url.startsWith(ImageUtil.cdnPrefix);
  };

  /**
   * 目前cdn已经配置指向cos
   * 将Cos源头改为cdn源头，这样才能应用cdn的图片处理规则
   * */
  static replaceCdnPrefixForCos = (url: string): string => {
    if (url.startsWith(ImageUtil.cosPrefix)) {
      url = url.replace(ImageUtil.cosPrefix, ImageUtil.cdnPrefix);
    }
    return url;
  };

  /**
   * 将public图片全部放到cdn上
   * 根据名字直接拉取
   * */
  static getLocalImgFromCdn = (key: string): string => {
    // 迁移到cloudflare后，不再需要cdn
    /*const url = useRequestURL();
    const hostName = url.hostname;
    if (hostName == 'localhost') {
      return `/img/${key}.webp`;
    }
    const urlCdn = `${ImageUtil.cdnPrefix}/website_frontend/img/${key}.webp`;
    return urlCdn;*/
    return `/img/${key}.webp`;
  };

  /**
   * cdn图片管理配置了一系列的图片处理规则，比如webp转换，图片大小转换等
   * 下面的方法都是用来处理cdn自身图片的
   * 比如cdn上有一张图片，1024x306，但是ui需要的是500x150，那么可以使用下面的方法getWebpUrlWidth500进行转换
   * 目前已经支持的规则有:
   * 1. webp转换
   * 2. width_100
   * 3. width_300
   * 4. width_500
   * 5. width_600
   * 6. width_900
   * 7. width_500_height_300
   * */

  static getWebpUrl = (
    url: string,
    width?: number,
    height?: number
  ): string => {
    if (url && url != undefined && url != null && url != '') {
      //如果不是jpg、png、webp、jpeg结尾的图片，那么不处理
      if (
        !url.endsWith('.jpg')
        && !url.endsWith('.png')
        && !url.endsWith('.webp')
        && !url.endsWith('.jpeg')
      ) {
        return url;
      }
      url = ImageUtil.replaceCdnPrefixForCos(url);
      //如果已经是webp了，并且width和height都没有变化，那么不处理
      if (url.endsWith('.webp') && !width && !height) {
        return url;
      }
      if (ImageUtil.canProcessByCdn(url)) {
        let transformedUrl = `${url}/web_transformer`;
        const widthRules = [30, 50, 100, 200, 300, 500, 600, 900];
        const heightRules = [300];
        if (width && widthRules.includes(width)) {
          transformedUrl += `_width_${width}`;
        }
        if (height && heightRules.includes(height)) {
          transformedUrl += `_height_${height}`;
        }
        return transformedUrl;
      }
      return url;
    }
    return ImageUtil.defaultImageUrl;
  };

  // 获取文章图url
  static getArticleImageUrl = (url: string, width: number): string => {
    if (url != undefined && url.includes('placeholder')) {
      return ImageUtil.getLocalImgFromCdn('ic_rss_article_placeholder');
    }
    return ImageUtil.getWebpUrl(url, width);
  };
}
