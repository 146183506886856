export default defineAppConfig({
  devConfig: {
    env: 'dev',
    apiUrl: 'https://tapi.xgamer.gg/',
    cosUrl: 'https://xgamer-dev-1300342626.cos.ap-singapore.myqcloud.com',
    version: '1.0.1',
    googleAdsenseCount: '',
    googleSiteVerification: ''
  },
  productionConfig: {
    env: 'production',
    apiUrl: 'https://api.xgamer.gg/',
    cosUrl: 'https://cdn.xgamer.gg',
    version: '1.0.1',
    googleAdsenseCount: 'ca-pub-3519348030283904',
    googleSiteVerification: 'OD4RlM2c1uacG3S45w2Ellhtxg3hWFV91OoKcfUW9eA'
  }
});
