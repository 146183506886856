import { default as _91tab_936HaMBJnO3LMeta } from "/opt/buildhome/repo/pages/[tab].vue?macro=true";
import { default as indexCMVanMCzFOMeta } from "/opt/buildhome/repo/pages/index/index.vue?macro=true";
import { default as _91_46_46_46id_93sv0Sj3HZ3aMeta } from "/opt/buildhome/repo/pages/news/[...id].vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46id_93NluWl8TBvWMeta } from "/opt/buildhome/repo/pages/tier/[...id].vue?macro=true";
import { default as index2Njdj8UikKMeta } from "/opt/buildhome/repo/pages/tier/maker/index.vue?macro=true";
import { default as _91_46_46_46id_93uveIa6E8VzMeta } from "/opt/buildhome/repo/pages/wiki/[...id].vue?macro=true";
export default [
  {
    name: "tab",
    path: "/:tab()",
    component: () => import("/opt/buildhome/repo/pages/[tab].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id(.*)*",
    component: () => import("/opt/buildhome/repo/pages/news/[...id].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "tier-id",
    path: "/tier/:id(.*)*",
    component: () => import("/opt/buildhome/repo/pages/tier/[...id].vue").then(m => m.default || m)
  },
  {
    name: "tier-maker",
    path: "/tier/maker",
    component: () => import("/opt/buildhome/repo/pages/tier/maker/index.vue").then(m => m.default || m)
  },
  {
    name: "wiki-id",
    path: "/wiki/:id(.*)*",
    component: () => import("/opt/buildhome/repo/pages/wiki/[...id].vue").then(m => m.default || m)
  }
]