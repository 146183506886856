import { defineNuxtPlugin } from '#app';
import { useCookie } from 'nuxt/app';
declare const localStorage: Window['localStorage'];
export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useAppConfig();
  const getConfigInfo = () => {
    const url = useRequestURL();
    const hostName = url.hostname;
    let config = {};
    console.log(`host name is ${  hostName}`)
    switch (hostName) {
      case 'localhost':
        config = appConfig.devConfig;
        break;
      case 'blackmyth.gg':
      case 'www.blackmyth.gg':
        config = appConfig.productionConfig;
        break;
      default:
        config = appConfig.devConfig;
        break;
    }
    // 预览域名
    if(hostName.includes('black-myth-wukong-release-and-preview')) {
      config = appConfig.productionConfig;
    }
    return config;
  };

  const isLogin = () => {
    const token = useCookie('login-token');
    const user = useCookie('login-user');
    return (
        token.value != undefined
        && token.value != null
        && token.value != ''
        && user.value != undefined
        && user.value != null
        && user.value != ''
    );
  };

  const logout = () => {
    const token = useCookie('login-token');
    const user = useCookie('login-user');
    token.value = '';
    user.value = '';
    useState('loginState').value = false;
  };

  const getLoginUser = () => {
    const user = useCookie('login-user');
    return user.value;
  };

  const getLoginToken = () => {
    const token = useCookie('login-token');
    return token.value;
  };

  const getTempUserId = () => {
    let userId = localStorage.getItem('tempUserId');
    // 检查是否存在名为 "tempUserId" 的 cookie
    const userIdCookie = getCookie('tempUserId');
    if (userId && !userIdCookie) {
      // 将userId存储到cookie中
      setCookie('tempUserId', userId, 365); // 设置过期时间为一年
    }
    if (!userId) {
      const chars
          = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      userId = '2'; //2开头的用户id为vue初次生成的临时用户，i开头是是flutter初次生成的临时用户
      for (let i = 0; i < 15; i++) {
        userId += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      localStorage.setItem('tempUserId', userId);
      // 将userId存储到cookie中
      setCookie('tempUserId', userId, 365); // 设置过期时间为一年
    }
    return userId;
  };

  const getUserId = () => {
    if (isLogin()) {
      return getLoginUser().userid;
    } else {
      return getTempUserId();
    }
  };

  // 获取指定名称的 cookie 值
  const getCookie = (name: string) => {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=');
      if (cookie[0] === name) {
        return cookie[1];
      }
    }
    return null;
  };

  // 设置 cookie
  const setCookie = (name: string, value: string, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    const isLocalhost = location.hostname === 'localhost';
    console.log('isLocalhost:', isLocalhost);
    const secure = isLocalhost ? '' : '; Secure';
    const sameSite = '; SameSite=Strict';
    document.cookie = `${name}=${value}${expires}${secure}${sameSite}; path=/`;
  };

  /**
   * 设置是否为移动端
   * **/
  if (process.server) {
    const headers = useRequestHeaders();
    const userAgent = headers['user-agent'];
    const isMobile = /mobile/i.test(userAgent);
    useState('isMobileState').value = isMobile;
  } else {
    useState('isMobileState').value = window.matchMedia(
        '(max-width: 999px)'
    ).matches;
  }

  const isDevEnv = () => {
    return getConfigInfo().env === 'dev';
  };

  nuxtApp.provide('getConfigInfo', getConfigInfo);
  nuxtApp.provide('getTempUserId', getTempUserId);
  nuxtApp.provide('setCookie', setCookie);
  nuxtApp.provide('getUserId', getUserId);
  nuxtApp.provide('getLoginUser', getLoginUser);
  nuxtApp.provide('getLoginToken', getLoginToken);
  nuxtApp.provide('isLogin', isLogin);
  nuxtApp.provide('logout', logout);
  nuxtApp.provide('isDevEnv', isDevEnv);
});
