import type { GameDetail } from "@/components/pb/entity/game";

export const useSelectedGameDetail = () => useState<GameDetail>('selectedGameDetail', () => {
    return {
    };
})

export const useLoginState = () => useState<boolean>('loginState', () => false)

export const useIsMobileState = () => useState<boolean>('isMobileState', () => false)

//detail页面的深度，实现关闭的时候关闭所有的detail页面，从而直接返回到list的原来位置
export const detailPageDepth = () => useState<number>('detailPageDepth', () => 0)

