<template>
  <div class="game-list">
    <NuxtLink v-for="game in gameList" :key="game.key" :href="getGameUrl(game)" class="game-item">
      <span>{{ game.name }}</span>
    </NuxtLink>
  </div>
</template>
<script lang="ts" setup>
const gameList = [
  {
    tabs: [
      {
        key: 'map',
        name: 'Map'
      },
      {
        key: 'tier-list',
        name: 'Tier List'
      }
    ],
    key: 'elden-ring',
    name: 'ELDEN RING'
  },
  {
    id: 10098,
    tabs: [
      {
        key: 'map',
        name: 'Map'
      }
    ],
    key: 'fallout-76',
    name: 'Fallout 76'
  },
  {
    id: 10061,
    tabs: [
      {
        key: 'map',
        name: 'Map'
      },
      {
        key: 'tier-list',
        name: 'Tier List'
      }
    ],
    key: 'dragon-s-dogma-2',
    name: "Dragon's Dogma 2"
  },
  {
    id: 10088,
    tabs: [
      {
        key: 'map',
        name: 'Map'
      },
      {
        key: 'tier-list',
        name: 'Tier List'
      }
    ],
    key: 'fallout-4',
    name: 'Fallout 4'
  },
  {
    id: 10059,
    tabs: [
      { key: 'palworld-calculator', name: 'Calculator' },
      {
        key: 'map',
        name: 'Map'
      },
      {
        key: 'tier-list',
        name: 'Tier List'
      }
    ],
    key: 'palworld',
    name: 'Palworld'
  },
  {
    id: 17,
    tabs: [
      {
        key: 'ship',
        name: 'Ship Builds'
      },
      {
        key: 'database',
        name: 'Database'
      },
      {
        key: 'tier-list',
        name: 'Tier List'
      },
      {
        key: 'outpost-calculator',
        name: 'Outpost Calculator'
      }
    ],
    key: 'starfield',
    name: 'Starfield'
  }
];
const getGameUrl = (game) => {
  return `https://gametool.gg/en/games/${game.key}/${game.tabs[0].key}`;
};
</script>
<style scoped>
.game-list {
  width: 100%;
  display: flex;
  overflow-x: auto;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 30px;
  z-index: 1;
  background-color: #131f28;
  opacity: 0.8;
  overflow: visible;
}

.game-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
}
</style>
