import CacheMgr, { CacheType } from '~/util/cache/cache-mgr.ts';

/**
 * 网络请求工具类，说明文档 https://iwiki.woa.com/p/4009425106
 */
export default class NetworkMgr {
  private static instance: NetworkMgr;
  private apiUrl: string;
  private locale: string;
  private token: string;
  private userId: string;

  public static getInstance(): NetworkMgr {
    if (NetworkMgr.instance == null) {
      NetworkMgr.instance = new NetworkMgr();
    }
    return NetworkMgr.instance;
  }

  public setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
    return this;
  }

  public setLocale(locale: string) {
    this.locale = locale;
    return this;
  }

  public setToken(token: string) {
    this.token = token;
    return this;
  }

  public setUserId(userId: string) {
    this.userId = userId;
    return this;
  }

  /**
   * 适合页面级别的请求
   *
   * @param name 唯一键名，必填
   * @param path 请求地址，必填
   * @param params 请求参数，可空
   * @param extraOption 可选属性，可空
   * @param cacheType 缓存类型，默认不缓存
   */
  public async request(
    name: string,
    path: string,
    params?: object,
    extraOption?: object,
    cacheType: CacheType = CacheType.NONE
  ) {
    return useAsyncData(
      name,
      () => this.fetch(path, params, cacheType),
      extraOption ?? {}
    );
  }

  /**
   * 适合组件级别的请求
   * @param path 请求地址，必填
   * @param params 请求参数，可空
   * @param cacheType 缓存类型，默认不缓存
   */
  public async fetch(
    path: string,
    params?: object,
    cacheType: CacheType = CacheType.NONE
  ) {
    // 先获取缓存数据
    const cacheKey = CacheMgr.generateKey(
      cacheType,
      path + this.locale,
      params
    );
    if (CacheMgr.isCacheScene(cacheType)) {
      const cache = CacheMgr.get(cacheKey);
      if (cache != null) {
        return cache;
      }
    }
    // 无可用缓存发起请求
    const response = await $fetch(
      this.apiUrl + path,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          lang: this.locale,
          authorization: this.token,
          userid: this.userId
        },
        body: JSON.stringify(params ?? {})
      },
      []
    );
    // 统一处理异常情况
    this.handleError(response.error);
    // 添加缓存
    if (CacheMgr.isCacheScene(cacheType)) {
      CacheMgr.set(response, response.error, cacheKey);
    }
    return response;
  }

  private handleError(error: object) {
    if (error != undefined) {
      console.log('Error is ', error.result);
      // 登录态失效
      if (error.result == 103) {
        useNuxtApp().$logout();
        location.reload();
      }
    }
  }
}
