<template>
  <div
    v-if="tabs.length > 0"
    class="custom-tabBar"
    :class="{ 'custom-tabBar-center': tabs.length < 5 }"
  >
    <NuxtLink class="web-title" to="/" @click="activeTab = 'home'"
      ><i>BLACKMYTH.GG</i></NuxtLink
    >
    <NuxtLink
      v-for="(tab, index) in tabs"
      :key="index"
      :class="{ 'tab-active': activeTab === tab.key }"
      :to="jumpTabUrl(tab.key)"
      class="tab-item"
      style="text-decoration: none"
      @click="activeTab = tab.key"
    >
    <span v-if="!(tab.key == 'map' && mapTitles.length !== 1)">{{tab.name}}</span>
    <div
        v-else
        class="dropdown"
        @mouseover="showDropdown = true"
        @mouseleave="showDropdown = false"
      >
        <span>{{tab.name }}</span>
        <span style="display: flex;height: 22px">
          <DownArrow></DownArrow>
        </span>
        <ul v-if="showDropdown" class="dropdown-menu">
          <NuxtLink
            v-for="(mapId,title) in titleMap"
            :key="title"
            :href="jumpMapUrl(mapId)"
          >
            <li :style="getStyle(title)">{{ title }}</li>
          </NuxtLink>
        </ul>
      </div>
    <div v-if="activeTab === tab.name" class="tab-underline"></div>
    </NuxtLink>
  </div>
</template>

<script setup>
import DownArrow from '~/assets/svg/down-arrow.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const showDropdown = ref(false);
const selectedTitle = ref('');
const titleMap = ref({})
const jumpTabUrl = (tabKey) => {
  if (tabKey == 'home') {
    return '/';
  }
  let param = `/${tabKey}`;
  return param;
};
const tabs = ref([
  { name: 'home', key: 'home' },
  { name: 'wallpaper', key: 'wallpaper' },
]);
let keys = ref([])
if (useSelectedGameDetail().value && useSelectedGameDetail().value.tabs) {
  tabs.value = useSelectedGameDetail().value.tabs;
  keys.value = tabs.value.map(item => item.key)
  //如果包含name:map，则删除
}
let activeTab = ref(keys.value.includes(route.params.tab)?route.params.tab:'home');

const mapTitles = computed(()=>{
  if(tabs.value.find(item=>item.key==='map')){
    var titles = tabs.value.find(item=>item.key==='map').titles;
    titleMap.value = tabs.value.find(item=>item.key==='map').titleMap;
    selectedTitle.value = titles[route.query.mapId ?? 0];
    return titles;
  }
});
const getStyle = (title) => {
  if(selectedTitle.value === title) {
    return {
      color:'orangered'
    }
  }
};
const jumpMapUrl = (mapId) => {
  if(mapId === '0') 
    return `/map`;
  else{
    return `/map?mapId=${mapId}`;
  }
}
onMounted(() => {
  if (route.params.tab === 'map') {
    selectedTitle.value = mapTitles.value[route.query.mapId] || mapTitles.value[0];
  }
  else {
    selectedTitle.value = ""
  }
});
console.log(`active tab is ${  activeTab.value}`)
console.log(`route.params.tab is ${  route.params.tab}`)
</script>
<style scoped>
.web-title {
  position: absolute;
  font-size: 28px;
  left: 16px;
  color: #ff3300;
  top: -10px;
}
.custom-tabBar {
  width: 100%;
  display: flex;
  overflow-x: auto;
  justify-content: center;
  position: fixed;
  top: 30px;
  left: 0;
  height: 50px;
  z-index: 1;
  /* background-color: rgba(19, 31, 40, 0.5); */
  background-color: #131f28;
  opacity: 0.8;
  overflow: visible;
}

.custom-tabBar span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.custom-tabBar .tab-item {
  padding: 15px 26px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  height: 12px;
}

.custom-tabBar .tab-item span {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

.custom-tabBar .tab-item:hover {
  color: rgba(255, 255, 255, 0.6);
}

.custom-tabBar .tab-active span {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.custom-tabBar .tab-underline {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 2px;
  background-color: rgba(255, 255, 255, 1);
}
.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dropdown-menu {
  position: absolute; /* 绝对定位 */
  left: 50%;
  top: 95%;
  transform: translateX(-50%); /* 水平居中 */
  width: 250px;
  height: 500px;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  margin: 0;
  padding: 0;
  background-color: rgba(19, 31, 40, 1);
  color: white;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
}
.dropdown-menu li {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 16px;
  color: black;
  background-color: rgba(19, 31, 40, 1);
  color: white;
  line-height: 1.5;
  font-weight: 500;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
  color: black;
}
@media screen and (max-width: 999px) {
  .custom-tabBar {
    width: calc(100vw - 16px);
    position: fixed;
    left: 0;
    top: 56px;
    height: 40px;
    display: flex;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(25, 40, 52, 164);
    gap: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .custom-tabBar-center {
    justify-content: center;
  }

  .custom-tabBar .tab-item {
    padding: 0px 0px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .custom-tabBar .tab-item span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  .custom-tabBar .tab-item:hover {
    color: rgba(255, 255, 255, 0.6);
  }

  .custom-tabBar .tab-active span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }

  .custom-tabBar .tab-underline {
    position: absolute;
    bottom: 0px;
    left: 12px;
    right: 12px;
    height: 2px;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
