import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("intersect", {
    beforeMount(el, binding) {
      // 遇到个问题，路由打开页面的时候，如果内容不足一页，intersect的结果默认是false。
      // 因此通过在下一个事件循环中开始观察
      setTimeout(() => {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            binding.value(true);
          } else {
            binding.value(false);
          }
        });
        observer.observe(el);
        el.$observer = observer;
      }, 0);
    },
    unmounted(el) {
      if (el && el.$observer) {
        el.$observer.disconnect();
      }
    },
  });
});
