import { LRUCache } from 'lru-cache';

/**
 * 用于缓存请求结果数据，背景 https://iwiki.woa.com/p/4009446922
 */
export default class CacheMgr {
  private static cache = new LRUCache({
    max: 100, // 暂定缓存100个数据
    ttl: 1000 * 60 * 60 * 24 // 暂定保存1天
  });
  private static localVersion = '';

  public static clear(currentVersion: string) {
    if (this.localVersion != currentVersion) {
      // 版本不一致时清空缓存
      this.cache.clear();
      this.localVersion = currentVersion;
    }
  }

  public static get(key?: string) {
    if (key == null || key.length === 0) {
      return null;
    }
    return this.cache.get(key);
  }

  public static set(data: object, error: object, key?: string) {
    if (key == null || key.length === 0) {
      return;
    }
    // 不缓存异常情况
    if (error != undefined || data == null) {
      return;
    }
    // 已有缓存数据
    if (this.cache.has(key)) {
      return;
    }
    this.cache.set(key, data);
  }

  public static generateKey(type: CacheType, reqTag: string, params?: object) {
    if (type == CacheType.NONE) {
      return '';
    }
    const tpParams = JSON.parse(JSON.stringify(params ?? {}));
    if (type == CacheType.BOTH_CLIENT_SERVER) {
      // 对存储到服务端的数据，去掉userid等身份标识信息
      if (tpParams != null && 'userid' in tpParams) {
        delete tpParams.userid;
      }
    }
    let key = reqTag;
    for (const id in tpParams) {
      key = key + tpParams[id];
    }
    return key;
  }

  public static isCacheScene(cacheType: CacheType) {
    return (
      (cacheType == CacheType.ONLY_CLIENT && process.client)
      || (cacheType == CacheType.ONLY_SERVER && process.server)
      || cacheType == CacheType.BOTH_CLIENT_SERVER
    );
  }
}

export const enum CacheType {
  NONE = 0, // 不缓存
  ONLY_CLIENT = 1, // 仅缓存在客户端
  ONLY_SERVER = 2, // 仅缓存在服务端
  BOTH_CLIENT_SERVER = 3 // 客户端和服务端都缓存，在生成key时会去掉userid等个人数据，个性化的数据请使用 ONLY_CLIENT
}
